import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGetAllTaskQuery, useGetAllTaskRegularQuery, useDeleteTaskMutation } from '../../../slices/taskApiSlice';
import { CardGrid } from '../../../ui-kit/Card/CardTask';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import PaginableList from '../../../ui-kit/PaginableList';
import { formatMessage } from '../../../locale'
import { useIntlContext } from '../../../locale/IntlProviderWrapper'
import { TaskPermisson } from '../../../enum/Task/TaskPermisson';

interface  TaskListProps {}

export const  TaskList: React.FC<TaskListProps> = () => {
  const { intl } = useIntlContext();
  const navigate = useNavigate();
  const { userInfo, userCompany, permissions } = useSelector((state: RootState) => state.auth);
  const canDo = (permission: string) => permissions && !!permissions[permission];
  const [deleteTask] = useDeleteTaskMutation();

  const [currentPage, setCurrentPage] = useState(1);

  return (
    <PaginableList query={userInfo?.data?.role?.name === 'power_user' || userInfo?.data?.role?.name === 'super_admin' ? useGetAllTaskQuery : useGetAllTaskRegularQuery} currentPage={currentPage}>
      {(task, isLoading, error, refetch) => {

        if (isLoading) return <div><p>{formatMessage({ id: 'loading.loading', defaultMessage: 'Loading...' })}</p></div>;
        if (error) return <div><p>{formatMessage({ id: 'loading.errorLoadingTask', defaultMessage: 'Error while loading Task' })}</p></div>

        const handleUpdate = (id: number) => {
          navigate(`/task/edit/${id}`);
          refetch();
        };

        const handleDelete = async (id: number) => {
          try {
            await deleteTask({ id }).unwrap();
            console.log(`Task with ID ${id} deleted successfully.`);
            refetch();
          } catch (error) {
            console.error('Error deleting investigation:', error);
          }
        };

        const cardsData = task.map((task: any) => ({
          id: task?.id,
          title: task?.title,
          assignee: task?.assignee,
          creator: task?.creator,
          taskType: task?.task_type,
          dueDate: task?.due_date,
          completedDate: task?.completed_date,
          status: task?.status?.name,
          createdAt: task?.created_at,
          updatedAt: task?.updated_at,
          viewLink: `/task/${task.id}`,
          handleUpdate,
          handleDelete,
        }));

        return <CardGrid cards={cardsData} />;
      }}
    </PaginableList>
  );
};

export default TaskList;
