import styled from 'styled-components';

export const TaskGrid = styled.div`
  display: grid;
  gap: 20px;
  width: 100%;
  cursor: pointer;

  @media (min-width: ${(props) => props.theme.breakPoints.sm}) {
    grid-template-columns: repeat(1, 2fr);
  }
  @media (min-width: ${(props) => props.theme.breakPoints.md}) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (min-width: ${(props) => props.theme.breakPoints.lg}) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (min-width: ${(props) => props.theme.breakPoints.xl}) {
    grid-template-columns: repeat(4, 1fr);
  }
`;

export const Button = styled.button`
  background: #efefef;
  border-radius: 3px;
  padding: 3px;
  padding-bottom: 1px;
  border: none;
  cursor: pointer;
  font-size: 16px;
  color: #000;
`;

export const TaskContainer = styled.div`
  position: relative;
  padding: 16px;
  min-height: 200px;
  min-width: 250px;
  display: flex;
  flex-direction: column;
  transition: transform 0.3s;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  &:hover {
    transform: scale(1.05);
  }
`;

export const Entry = styled.div`
  margin: 0;
  font-size: 14px;
  color: rgba(106, 106, 106, 1);
  max-height: 100px; 
  height: 100px;
  overflow: hidden;
  margin-top: 5px;
  margin-bottom: 5px;
`;

export const P = styled.p`
  font-size: 12px;
  color: grey;
`;

export const Header = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 20px;
`;


export const ListContainer = styled.div`
    padding: 20px;
`;

export const Actions = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  gap: 10px;
`;

export const IconButton = styled.button`
  background: #efefef;
  border-radius: 3px;
  padding: 3px;
  padding-bottom: 1px;
  border: none;
  cursor: pointer;
  font-size: 16px;
  color: #000;
`;
