import React from 'react'
import { StyledAsterix } from './ui'
import { useSelector } from 'react-redux'


const Asterix = () => {
    const { userCompany } = useSelector((state: any) => state.auth)
    return(
        <StyledAsterix style={{color: userCompany?.main_color}}>*</StyledAsterix>
    )
}

export default Asterix
