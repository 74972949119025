// Checkbox.tsx

import React, { InputHTMLAttributes } from 'react';
import {
  StyledCheckbox,
  CheckboxPlaceholder,
  CheckboxWrapper,
  Label,
  CheckboxContainer,
  StyledIcon,
} from './ui';
import Error from '../ErrorMessage/Message';
import { useTheme } from 'styled-components';
import { useField } from '../hooks';
import { Asterix } from '../../../';
import { MdCheck } from 'react-icons/md'; // Import check icon

type CheckboxProps = InputHTMLAttributes<HTMLInputElement> & {
  label?: any;
  className?: string;
  hint?: string;
  error?: string;
  name?: string;
  required?: boolean;
};

export const Checkbox = ({
                           label,
                           className,
                           checked,
                           id,
                           style,
                           disabled,
                           hint,
                           error,
                           onChange,
                           name,
                           required,
                           ...props
                         }: CheckboxProps) => {
  const theme = useTheme();

  return (
    <CheckboxContainer>
      <Label
        htmlFor={id}
        title={label}
        className={className}
        disabled={disabled}
      >
        <CheckboxWrapper>
          <CheckboxPlaceholder
            {...props}
            type="checkbox"
            id={id}
            checked={checked}
            disabled={disabled}
            onChange={onChange}
            name={name}
          />
          <StyledCheckbox
            style={style}
            checked={checked}
            disabled={disabled}
            hasError={!!error}
          >
            <StyledIcon checked={checked}>
              <MdCheck />
            </StyledIcon>
          </StyledCheckbox>
        </CheckboxWrapper>

        <span>
          {label ?? ''}
          {required && <Asterix />}
        </span>
      </Label>

      <Error name={name}>{error}</Error>
    </CheckboxContainer>
  );
};

type FormikCheckboxProps = CheckboxProps & {
  name: string;
};

export const FormikCheckbox = ({
                                 name = '',
                                 onChange,
                                 ...props
                               }: FormikCheckboxProps) => {
  const [field] = useField({
    name,
    onChange,
    type: 'checkbox',
  })

  const handleChange = () => {
    field.onChange(!field.value, field)
  }

  return <Checkbox {...props} {...field} name={name} onChange={handleChange} />
}

export default FormikCheckbox