import { apiSlice } from './apiSlice';

export const taskTypeApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllTaskTypes: builder.query({
      query: ({ search = '', page = 1 }) => ({
        url: `/api/v1/admin/tasks/types?search=${encodeURIComponent(search)}&page=${page}`,
        method: 'GET',
    }),
  }),
    createTaskType: builder.mutation({
      query: ({ data }) => ({
        url: `/api/v1/admin/tasks/types/`,
        method: 'POST',
        body: data,
      }),
    }),
    getOneTaskType: builder.query({
      query: ({id}) => ({
        url: `/api/v1/admin/tasks/types/${id}/`,
        method: 'GET',
      }),
    }),
    updateTaskType: builder.mutation({
      query: ({ data, id }) => ({
        url: `/api/v1/admin/tasks/types/${id}?_method=put`,
        method: 'POST',
        body: data,
      }),
    }),
      deleteTaskType: builder.mutation({
        query: ({ id }) => ({
          url: `/api/v1/admin/tasks/types/${id}/`,
          method: 'DELETE',
      }),
    }),
    getOptionValueCustomFields: builder.query({
      query: ({ field_type }) => ({
        url: `/api/v1/admin/tasks/options/`,
        method: 'GET',
        params: { field_type },
      }),
    }),
  }),
});

export const { useGetAllTaskTypesQuery, useCreateTaskTypeMutation, useGetOneTaskTypeQuery, useUpdateTaskTypeMutation, useDeleteTaskTypeMutation, useGetOptionValueCustomFieldsQuery } = taskTypeApiSlice;
