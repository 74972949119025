import React, {
  Component,
  ForwardedRef,
  forwardRef,
  InputHTMLAttributes,
  useEffect,
  useRef,
  useState,
} from 'react'
import {
  Container,
  IconsContainer,
  Actions,
  StyledInput,
  Label,
  StyledInputProps,
} from './ui'
import { IoEyeOffOutline } from 'react-icons/io5'
import { IoEyeOutline } from 'react-icons/io5'
import { FieldWrapper } from '../../FieldWrapper'
import { useField } from '../hooks'
import Message from '../ErrorMessage/Message'
import { Asterix } from '../../../'
import { useSelector } from 'react-redux'

export type InputProps = StyledInputProps &
  Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'> & {
    // overridded since we are not passing event object _anywhere_
    onChange?: (value: any) => void
    components?: {
      Input?: typeof React.Component
      Label?: typeof FieldWrapper
    }
    showCustomPlaceholder?: boolean
    hint?: string
    endAdornment?: React.ReactNode
    endAdornmentProps?: React.BaseHTMLAttributes<HTMLDivElement>
    disabled?: boolean
    name?: string
    required?: boolean
    info?: string
    infoWithIcon?: boolean
    errorWithIcon?: boolean
    iconCount?: number
    exceptSymbols?: string[]
    label: any
  }

export const Input = forwardRef(
  (
    {
      className,
      components = {},
      hint,
      placeholder,
      showCustomPlaceholder = true,
      error,
      endAdornment,
      endAdornmentProps,
      name,
      required,
      info,
      infoWithIcon,
      errorWithIcon,
      iconCount,
      label,
      exceptSymbols = ['e', 'E', '+', '-', '.', ','],
      ...props
    }: InputProps,
    ref: ForwardedRef<HTMLInputElement | null>
  ) => {
    const { disabled, value, type } = props
    const Input = (components?.Input || StyledInput) as typeof Component
    const inputRef = useRef<HTMLInputElement | null>(null)
    const [inputType, setInputType] = useState(type)
    const [focused, setIsFocused] = useState(false)
    const isPasswordType = type === 'password'
    const isNumberType = type === 'number'
    const hasHint = !!hint
    const inputIconCount = [hasHint, isPasswordType].filter(Boolean).length || 0
    const { userCompany } = useSelector((state: any) => state.auth)

    useEffect(() => {
      if (ref) {
        if (typeof ref === 'function') {
          ref(inputRef.current)
        } else {
          ref.current = inputRef.current
        }
      }
    }, [ref])

    const changeInputType = (type: string) => {
      if (inputRef?.current) {
        setInputType(type)
        inputRef.current.type = type
      }
    }

    const handleKeyDown = (e: KeyboardEvent) => {
      if (isNumberType) {
        exceptSymbols.includes(e.key) && e.preventDefault()
      }
    }

    return (
      <>
        <Container className={className}>
          {label && (
            <Label>
              {label}
              {required && <Asterix />}
            </Label>
          )}
          <FieldWrapper disabled={disabled} error={!!error} color={userCompany?.button_color}>
            <Input
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              ref={inputRef}
              {...props}
              placeholder={placeholder}
              value={value || ''}
              hasValue={value}
              name={name}
              onFocus={() => setIsFocused(true)}
              onBlur={() => setIsFocused(false)}
              onKeyDown={handleKeyDown}
              iconCount={iconCount ? iconCount : inputIconCount}
            />
            <Actions>
              {(hint || isPasswordType || endAdornment) && (
                <IconsContainer {...endAdornmentProps}>
                  {endAdornment}

                  {isPasswordType && (
                    <>
                      {inputType === 'password' ? (
                        <span onClick={() => changeInputType('text')}>
                          <IoEyeOffOutline size={20} />
                        </span>
                      ) : (
                        <span onClick={() => changeInputType('password')}>
                          <IoEyeOutline size={20} />
                        </span>
                      )}
                    </>
                  )}
                </IconsContainer>
              )}
            </Actions>
          </FieldWrapper>
          <Message
            name={name}
            type={(error && 'error') || (info && 'info') || undefined}
            infoWithIcon={infoWithIcon}
            errorWithIcon={errorWithIcon}
          >
            {error ? error : info}
          </Message>
        </Container>
      </>
    )
  }
)

export const FormikInput = ({ name, onChange, ...props }: InputProps) => {
  const [field] = useField({ name, onChange, ...props })

  return (
    <Input
      {...props}
      {...field}
      name={name}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
        field.onChange(e.target.value)
      }
    />
  )
}

export default FormikInput
