import styled from 'styled-components';

export const MainDiv = styled.div`
    background: white;
    padding: 0 10px 0 10px;
    border-radius: 20px;
    z-index: 9999;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 72vh;
`;

export const TaskTypeContainer = styled.div`
    margin-top: 20px;
    text-align: left;
    margin-bottom: 0.5rem;
`;

export const Label = styled.label`
  margin-top: 10px;
  margin-bottom: 0.5rem;
  font-size: 14px;
  display: inline-block;
`

export const DivName = styled.div`
  padding: 0.5rem;
  margin-bottom: 1rem;
  font-size: 14px;
  text-align: left;
`

export const TextArea = styled.div`
  width: 100px;
  height: 100px;
  padding: 0.5rem;
  margin-bottom: 1rem;
`

export const Button = styled.button`
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  cursor: pointer;
  width: 300px;
  border-radius: 8px;
`
