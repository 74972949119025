import React, { useEffect, useState } from 'react';
import { MainDiv, TaskTypeContainer, Label, Input, TextArea, DIV } from './ModalContent.style';
import Select, { StylesConfig } from 'react-select';
import { CustomFieldPermisson } from '../../../../enum/TaskPermisson/CustomFieldPermisson';
import CheckBox from '../checkbox/CheckBox';
import { useIntlContext } from '../../../../locale/IntlProviderWrapper';
import { formatMessage } from '../../../../locale';
import { useSelector } from 'react-redux';
interface TaskType {
  mode?: any
  taskTypes?: any
  selectedType?: any
  setSelectedType?: any
  nameTypeEn?: any
  setNameTypeEn?: any,
  nameTypeSv?: any,
  setNameTypeSv?: any,
  descriptionEn?: any,
  setDescriptionEn?: any
  descriptionSv?: any,
  setDescriptionSv?: any
  isInput?: any
  setIsInput?: any
  isOutput?: any
  setIsOutput?: any
  isRequired?: any
  setIsRequired?: any
  isMulti?: any
  setIsMulti?: any
  Editor?: any;
}

function ModalContent({ mode, taskTypes, selectedType, setSelectedType, nameTypeEn, setNameTypeEn, nameTypeSv, setNameTypeSv, descriptionEn, setDescriptionEn, descriptionSv, setDescriptionSv, 
  isInput, setIsInput, isOutput, setIsOutput, isRequired, setIsRequired, isMulti, setIsMulti, Editor
 }: TaskType) {
  const { userCompany } = useSelector((state: any) => state.auth)
  const { intl } = useIntlContext();

  const options = taskTypes.map((type: any) => ({
    value: type.id,
    label: type.title,
  }));

  const handleChange = (selectedOption: any) => {
    setSelectedType(selectedOption);
  };

  const customStyles: StylesConfig = {
    control: (provided, state) => ({
      ...provided,
      width: 300,
      textAlign: 'left',
      fontWeight: 'normal',
      fontSize: 14,
      borderRadius: 8,
      zIndex: 9999,
      borderColor: state.isFocused ? userCompany?.button_color : provided.borderColor,
      boxShadow: state.isFocused ? `0 0 0 1px ${userCompany?.button_color}` : provided.boxShadow,
      '&:hover': {
        borderColor: userCompany?.button_color,
      },
    }),
    option: (provided) => ({
      ...provided,
      textAlign: 'left',
      fontWeight: 'normal',
      fontSize: 12,
    }),
    menu: (provided) => ({
      ...provided,
      width: 300,
      overflowY: 'auto',
    }),
    menuList: (provided) => ({
      ...provided,
      maxHeight: 135,
      width: 300,
      "::-webkit-scrollbar": {
        width: "10px",
        height: "2px",
      },
      "::-webkit-scrollbar-track": {
        background: "lightGrey",
        borderRadius: "8px"
      },
      "::-webkit-scrollbar-thumb": {
        background: "#888",
        borderRadius: "8px"
      },
      "::-webkit-scrollbar-thumb:hover": {
        background: "#555",
        borderRadius: "8px"
      }
    }),
  };
  const isEditorRequired = (type: any) => {
    return [
      CustomFieldPermisson.EDITOR,
    ].includes(type?.value);
  }

  const isDescriptionRequired = (type: any) => {
    return [
      CustomFieldPermisson.DROPDOWN,
    ].includes(type?.value);
  };

  const isMultiselect = (type: any) => {
    return [
      CustomFieldPermisson.DROPDOWN,
      CustomFieldPermisson.DEPARTMENT,
      CustomFieldPermisson.PROJECT_GROUP,
      CustomFieldPermisson.JOB_TITLE,
      CustomFieldPermisson.OFFICE,
      CustomFieldPermisson.USER,
    ].includes(type?.value);
  };

  return (
    <MainDiv>
      <h2 style={{paddingLeft: 15}}>
        {mode === 'create' ? formatMessage({ id: 'taskType.createCustomField', defaultMessage: 'Create Custom Field' })
          : formatMessage({ id: 'taskType.editCustomField', defaultMessage: 'Edit Custom Field' })}
      </h2>
    <DIV>
      <TaskTypeContainer>
        <Label style={{  textAlign: 'left' }}>{formatMessage({ id: 'taskType.fieldType', defaultMessage: 'Field Type' })}</Label>
          <Select
            value={selectedType}
            options={options}
            onChange={handleChange}
            placeholder={formatMessage({ id: 'taskType.selectType', defaultMessage: 'Select a task type...' })}
            isSearchable
            styles={customStyles}
          />
      </TaskTypeContainer>

      
      <div style={{display: 'flex', flexDirection: 'column'}}>
        <Label style={{ marginBottom: '0.5rem', textAlign: 'left' }}>{formatMessage({ id: 'taskType.customFieldName', defaultMessage: 'Custom Field Name' })} SV</Label>
        <Input
          type="text"
          value={nameTypeSv}
          onChange={(e) => setNameTypeSv(e.target.value)}
          placeholder={formatMessage({ id: 'taskType.placeholderCustomFieldName', defaultMessage: 'Enter a task type name' })}
          color={userCompany?.button_color}  
        />
      </div>

      <div style={{display: 'flex', flexDirection: 'column'}}>
        <Label style={{ marginBottom: '0.5rem', textAlign: 'left' }}>{formatMessage({ id: 'taskType.customFieldName', defaultMessage: 'Custom Field Name' })} EN</Label>
        <Input
          type="text"
          value={nameTypeEn}
          onChange={(e) => setNameTypeEn(e.target.value)}
          placeholder={formatMessage({ id: 'taskType.placeholderCustomFieldName', defaultMessage: 'Enter a task type name' })}
          color={userCompany?.button_color}  
        />
      </div>

      {selectedType &&  isDescriptionRequired(selectedType) && (
        <div style={{display: 'flex', flexDirection: 'column', textAlign: 'left'}}>
          <Label>{formatMessage({ id: 'taskType.options', defaultMessage: 'Options' })} SV</Label>   
          <TextArea
            value={descriptionSv}
            onChange={(e) => setDescriptionSv(e.target.value)}
            name="optionsSv"
            placeholder={formatMessage({ id: 'taskType.placeholderOptions', defaultMessage: 'Enter all options separated by comma' })}
            color={userCompany?.button_color}  
          />

          <Label>{formatMessage({ id: 'taskType.options', defaultMessage: 'Options' })} EN</Label>   
          <TextArea
            value={descriptionEn}
            onChange={(e) => setDescriptionEn(e.target.value)}
            name="optionsEn"
            placeholder={formatMessage({ id: 'taskType.placeholderOptions', defaultMessage: 'Enter all options separated by comma' })}
            color={userCompany?.button_color}  
          />
        </div>
        )}
        {selectedType && isEditorRequired(selectedType) &&(
          Editor
        )}

      <CheckBox
        isChecked={isInput}
        onChange={setIsInput}
        label={formatMessage({ id: 'taskType.isInput', defaultMessage: 'Is Input' })}
      />
      <CheckBox 
        isChecked={isOutput}
        onChange={setIsOutput}
        label={formatMessage({ id: 'taskType.isOutput', defaultMessage: 'Is Output' })}
      />
    {selectedType && isMultiselect(selectedType) && (
      <CheckBox 
        isChecked={isMulti}
        onChange={setIsMulti}
        label={formatMessage({ id: 'taskType.isMulti', defaultMessage: 'Is multiselect' })}
      />
    )}	
      <CheckBox 
        isChecked={isRequired}
        onChange={setIsRequired}
        label={formatMessage({ id: 'taskType.isRequired', defaultMessage: 'Is Required' })}
        />
    </DIV>
    </MainDiv>
  );
}

export default ModalContent;