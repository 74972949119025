import styled from 'styled-components';

export const MainDiv = styled.div`
    background: white;
    border-radius: 20px;
    z-index: 9999;
    text-align: left;
`;

export const TaskTypeContainer = styled.div`
    margin-top: 20px;
    text-align: left;
    margin-bottom: 0.5rem;
`;
export const DIV = styled.div`
  overflow-y: auto;
  max-height: 72vh;
  padding-left: 15px;
  padding-right: 35px;
  
  &::-webkit-scrollbar {
    margin: 0 10px 0 10px;  
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;
export const Label = styled.label`
  margin-top: 10px;
  margin-bottom: 0.5rem;
  font-size: 14px;
  display: inline-block;
`

export const Input = styled.input<{ color?: string }>`
  width: 300px;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 8px;
  margin-bottom: 1rem;
  font-size: 14px;
  resize: none;

   &:hover {
    border-color: ${({ color }) => color};
  }
    &:focus {
    border-color: ${({ color }) => color};
    outline: none;
  }
`

export const TextArea = styled.textarea<{ color?: string }>`
  width: 300px;
  height: 100px;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 8px;
  margin-bottom: 1rem;
  resize: none;

  &:hover {
    border-color: ${({ color }) => color};
  }
  &:focus {
    border-color: ${({ color }) => color};
    outline: none;
  }
`


