import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ToastState {
    customFieldCreated: any[];
}

const initialState: ToastState = {
    customFieldCreated: [], 
};

const customFieldSliceCreated = createSlice({
    name: 'taskTypeSliceCreated',
    initialState,
    reducers: {
        setCustomFieldCreated(state, action: PayloadAction<any[]>) {
            state.customFieldCreated = action.payload;
        },
    },
});

export const { setCustomFieldCreated } = customFieldSliceCreated.actions;
export default customFieldSliceCreated.reducer;
