import React, { useEffect, useState, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useIntlContext } from '../../../../locale/IntlProviderWrapper'
import { formatMessage } from '../../../../locale'
import Button from '../../../../ui-kit/Button/Button'
import {
  Actions,
  FormHeader,
  FormPaper,
  TabContent,
  FormContent,
  Column,
  ColumnTopLevel,
  TaskTypeContainer,
  Label,
  LabelWrapper,
  TextArea,
  LabelField,
} from './TaskForm.Style'
import CustomSelect from '../../../../ui-kit/Dropdown/TaskDropdown'
import { paths } from '../../../../config/paths'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import styles from './DatePicker.module.css'
import Row from '../../../../ui-kit/grid/Row'
import { FormikInput, FormikTextarea } from '../../../../ui-kit'
import WangEditorTask from '../../../../ui-kit/Form/components/Editor/WangEditorTask';
import { Field, Form, Formik } from 'formik'
import { useSelector } from 'react-redux'
import {
  useGetAllTaskTypesQuery,
  useGetOneTaskTypeQuery,
  useGetOptionValueCustomFieldsQuery
} from '../../../../slices/taskTypeApiSlice'
import {
  useGetOneTaskQuery,
  useCreateTaskMutation,
  useUpdateTaskMutation
} from '../../../../slices/taskApiSlice'
import { useGetAllUsersQuery } from '../../../../slices/usersApiSlices'
import {
  DROPDOW_OPTIONS,
  INPUT_FILED,
  DATE_OPTIONS,
  TASK_FORM_OPTIONS,
  EDITOR
} from '../../../../enum/Task/TaskFormPermisson'
import { GrNext, GrPrevious } from "react-icons/gr";
import CustomSelectMulti from '../../../../ui-kit/Dropdown/TaskDropdownMulti'
import Progressbar from './Progressbar'
import  WangEditorTaskCustomField  from '../../../../ui-kit/Form/components/Editor/WangEditorTaskCustomField'

interface InvestigationFormPageProps {
  mode: 'create' | 'edit'
}

export const TaskForm = ({ mode }: InvestigationFormPageProps) => {
  const navigate = useNavigate()
  const { intl } = useIntlContext()
  const { taskId } = useParams<{ taskId: string }>()
  const { userCompany, userInfo } = useSelector((state: any) => state.auth)

  const mainColor = userCompany?.main_color;
  const buttonColor = userCompany?.button_color;

  const [title, setTitle] = useState('')
  const [dueDate, setDueDate] = useState<Date | null>(null)
  const [step1, setStep1] = useState(true)
  const [step2, setStep2] = useState(false)
  const [step3, setStep3] = useState(false)


  const [selectedTaskType, setSelectedTaskType] = useState<any>(null)
  const [selectedAssignedTo, setSelectedAssignedTo] = useState<any>(null)
  const [selectedStatus, setSelectedStatus] = useState<any>(null)

  const [createTask] = useCreateTaskMutation()
  const [updatetask] = useUpdateTaskMutation()

  const {
    data: task,
    refetch: oneTaskrefetch
  } = useGetOneTaskQuery(
    { id: taskId, update: true },
    { skip: mode === 'create' }
  )

  const { data: taskTypesData, refetch: refetchAllTaskType } = useGetAllTaskTypesQuery({})
  const { data: taskType } = useGetOneTaskTypeQuery({ id: selectedTaskType?.value }, { skip: !selectedTaskType?.value })
  const { data: userList } = useGetAllUsersQuery({})

  const taskTypeOne = taskType?.data?.custom_fields || []

  const dropdownOptions = useMemo(() => {
    return taskType?.data?.custom_fields?.reduce((acc: any, field: any) => {
      if (field.field_type === TASK_FORM_OPTIONS.DROPDOWN) {
        acc[field.id] = field.options?.map((option: any) => {
          const translatedValue = option.translations.find((translation: any) => translation.language_code === intl.locale)?.translated_value || ''
          return { ...option, option_value: translatedValue }
        }) || []
      }
      return acc
    }, {}) || {}
  }, [taskType, intl.locale])
    
  useEffect(() => {
    refetchAllTaskType()
    return () => {
      localStorage.removeItem('uuids')
      localStorage.removeItem('uuidCustomField')
      localStorage.removeItem('frontId')
  };
  }, []);

  
  const initialValues = {
    content: task?.data?.content || '',
    customFields: taskType?.data?.custom_fields?.reduce((acc: any, field: any) => {
      const entityValue = task?.data?.custom_field_entity_values?.find((cf: any) => cf.custom_field_id === field.id)?.value
      const fieldValue = task?.data?.custom_field_values?.find((cf: any) => cf.custom_field_id === field.id)?.value
      switch (field.field_type) {
        case DROPDOW_OPTIONS.USER:
        case DROPDOW_OPTIONS.DEPARTMENT:
        case DROPDOW_OPTIONS.OFFICE:
        case DROPDOW_OPTIONS.JOB_TITLE:
        case DROPDOW_OPTIONS.PROJECT_GROUP:
          console.log('entityValue', entityValue)
            acc[field.id] = entityValue ? Array.isArray(entityValue) ? entityValue.map((val: any) => ({ value: val.id, label: val.name })) : { value: entityValue.id, label: entityValue.name } : null
          break
        case TASK_FORM_OPTIONS.DROPDOWN: 
            acc[field.id] = fieldValue ? fieldValue.map((val: any) => ({
              value: val.id,
              label: task?.data?.custom_fields?.find((cf: any) => cf.id === field.id)?.options?.find((option: any) => option.id === val.id)?.translations.find((translation: any) => translation.language_code === intl.locale)?.translated_value
            })) : []
          break
        case INPUT_FILED.STRING:
        case INPUT_FILED.NUMBER:
          acc[field.id] = fieldValue || ""
          break
        case DATE_OPTIONS.DATE:
            if (fieldValue && typeof fieldValue === 'object' && fieldValue.date) {
            acc[field.id] = fieldValue.date ||  null
            }
          break
        case EDITOR.EDITOR:
          console.log(fieldValue, field.editor)
          acc[field.id] = fieldValue || field.editor || ''
          if (field.entity_files) {
            const backUID = localStorage.getItem('uuidCustomField');
            const prevUUIDs = backUID ? JSON.parse(backUID) : [];
            const newUUIDs = field.entity_files.map((file: any) => file.uuid);
            const combinedUUIDs = Array.from(new Set([...prevUUIDs, ...newUUIDs]));
            localStorage.setItem('uuidCustomField', JSON.stringify(combinedUUIDs));
          }
          break
        default:
          break
      }
      return acc
    }, {}) || {}
  }

  const { data: userOptions } = useGetOptionValueCustomFieldsQuery(
    { field_type: DROPDOW_OPTIONS.USER },
    {
      skip: !taskType?.data?.custom_fields?.some((field: any) =>
        field.field_type === DROPDOW_OPTIONS.USER)
    }
  )

  const { data: deparmentOptions } = useGetOptionValueCustomFieldsQuery(
    { field_type: DROPDOW_OPTIONS.DEPARTMENT },
    {
      skip: !taskType?.data?.custom_fields?.some((field: any) =>
        field.field_type === DROPDOW_OPTIONS.DEPARTMENT)
    }
  )

  const { data: officeOptions } = useGetOptionValueCustomFieldsQuery(
    { field_type: DROPDOW_OPTIONS.OFFICE },
    {
      skip: !taskType?.data?.custom_fields?.some((field: any) =>
        field.field_type === DROPDOW_OPTIONS.OFFICE)
    }
  )

  const { data: jobTitleOptions } = useGetOptionValueCustomFieldsQuery(
    { field_type: DROPDOW_OPTIONS.JOB_TITLE },
    {
      skip: !taskType?.data?.custom_fields?.some((field: any) =>
        field.field_type === DROPDOW_OPTIONS.JOB_TITLE)
    }
  )

  const { data: projectGroupOptions } = useGetOptionValueCustomFieldsQuery(
    { field_type: DROPDOW_OPTIONS.PROJECT_GROUP },
    {
      skip: !taskType?.data?.custom_fields?.some((field: any) =>
        field.field_type === DROPDOW_OPTIONS.PROJECT_GROUP)
    }
  )

  const mapOptions = (optionsData: any) => optionsData?.data?.map((option: any) => ({
    value: option.id,
    label: option.name || option.fullname,
  }))

  const mapOptionsStatus = (optionsData: any) => optionsData?.data?.statuses.map((option: any) => ({
    value: option.id,
    label: option.name,
  }))

  const mapOptionsStatusEdit = (optionsData: any, selectedStatusId: any) => {
    const selectedStatus = optionsData?.find((option: any) => option.id === selectedStatusId);
    if (!selectedStatus) return [];
  
    return [
      ...selectedStatus.previous_statuses?.map((status: any) => ({
        value: status.id,
        label: status.name
      })) || [],
      ...selectedStatus.next_statuses?.map((status: any) => ({
        value: status.id,
        label: status.name
      })) || []
    ];
  }
  
  const optionsStatusEdit = mapOptionsStatusEdit(task?.data?.custom_field_statuses, task?.data?.status?.id)

  //options for TASKPERMISSION.DROPDWON works with dropdwon Option memo
  const mapOptionsDropdown = (optionsData: any) => optionsData?.map((option: any) => ({
    value: option.id,
    label: option.option_value,
    field_id: option.field_id
  }))

  const optionsUser = mapOptions(userOptions)
  const optionsDepartment = mapOptions(deparmentOptions)
  const optionsOffice = mapOptions(officeOptions)
  const optionsJobTitle = mapOptions(jobTitleOptions)
  const optionsProjectGroup = mapOptions(projectGroupOptions)
  const optionsAssignedTo = mapOptions(userList)
  const optionsTaskType  = mapOptions(taskTypesData)
  const optionsStatus = mapOptionsStatus(taskType)


  useEffect(() => {
    if (mode === 'edit' && taskId) {
      oneTaskrefetch()
    }
    if (task) {
      setSelectedTaskType({ value: task?.data?.task_type.id, label: task?.data?.task_type?.name })
      setSelectedAssignedTo({ value: task?.data?.assignee?.id, label: task?.data?.assignee?.fullname })
      setSelectedStatus({ value: task?.data?.status?.id, label: task?.data?.status?.name })
      setTitle(task?.data?.title)
      if (task?.data?.due_date) {
        setDueDate(new Date(task?.data?.due_date))
      }
    }
  }, [mode, taskId, task])


  useEffect(() => {
    if (mode === 'edit' && task?.data?.entity_files) {
      const backUID = localStorage.getItem('uuids');
      const prevUUIDs = backUID ? JSON.parse(backUID) : [];
  
      const newUUIDs = task.data.entity_files.map((file: any) => file.uuid);
      const combinedUUIDs = Array.from(new Set([...prevUUIDs, ...newUUIDs]));
  
      localStorage.setItem('uuids', JSON.stringify(combinedUUIDs))
    }
  }, [task?.data?.entity_files]);


  useEffect(() => {
    if (mode === 'create' && taskType?.data?.custom_fields?.some((field: any) => field.entity_files)) {
      const backUID = localStorage.getItem('uuidCustomField');
      const prevUUIDs = backUID ? JSON.parse(backUID) : [];
  
      const newUUIDs = taskType?.data?.custom_fields
        .filter((field: any) => field.entity_files)
        .flatMap((field: any) => field.entity_files.map((file: any) => file.uuid));
      const combinedUUIDs = Array.from(new Set([...prevUUIDs, ...newUUIDs]));
  
      localStorage.setItem('uuidCustomField', JSON.stringify(combinedUUIDs));
    }
  }, [taskType?.data?.custom_fields]);

  useEffect(() => {
    if (mode === 'edit' && task?.data?.custom_field_values?.some((field: any) => field.entity_files)) {
      const backUID = localStorage.getItem('uuidCustomField');
      const prevUUIDs = backUID ? JSON.parse(backUID) : [];
  
      const newUUIDs = task.data.custom_field_values
        .filter((field: any) => field.entity_files)
        .flatMap((field: any) => field.entity_files.map((file: any) => file.uuid));
      const combinedUUIDs = Array.from(new Set([...prevUUIDs, ...newUUIDs]));
  
      localStorage.setItem('uuidCustomField', JSON.stringify(combinedUUIDs));
    }
  }, [task?.data?.custom_field_values]);

  const handleSubmit = async (values: any) => {
    const customFieldValues: any = []
    const customFieldEntityValues: any = []
    const customUID = localStorage.getItem('uuidCustomField');
    const frontUID = localStorage.getItem('frontId');
    
    Object.keys(values.customFields).forEach((key) => {
      if (!isNaN(Number(key))) {
        const taskType = taskTypeOne.find((taskType: any) => taskType.id === Number(key))
        if (taskType.field_type === DATE_OPTIONS.DATE) {
          customFieldValues.push({
            custom_field_id: Number(key),
            value: values.customFields[key] instanceof Date ? new Date(values.customFields[key].getTime() + (mode === 'create' ? (24 * 60 * 60 * 1000) : 0)) : values.customFields[key]
          })
        } else if (taskType.field_type === DROPDOW_OPTIONS.USER ||
          taskType.field_type === DROPDOW_OPTIONS.DEPARTMENT ||
          taskType.field_type === DROPDOW_OPTIONS.OFFICE ||
          taskType.field_type === DROPDOW_OPTIONS.JOB_TITLE ||
          taskType.field_type === DROPDOW_OPTIONS.PROJECT_GROUP) {
          customFieldEntityValues.push({
            custom_field_id: Number(key),
            value: Array.isArray(values.customFields[key])
              ? values.customFields[key].map((item: any) => ({ id: item.value }))
              : [{ id: values.customFields[key].value }]
          })
        } else if (taskType.field_type === TASK_FORM_OPTIONS.DROPDOWN) {
          customFieldValues.push({
            custom_field_id: Number(key),
            value: Array.isArray(values.customFields[key])
              ? values.customFields[key].map((item: any) => ({ id: item.value }))
              : [{ id: values.customFields[key].value }]
          })
        } else if (taskType.field_type === EDITOR.EDITOR) {
          const editorField: any = {
            custom_field_id: Number(key),
            value: values.customFields[key]
          };
          if (customUID) {
            try {
              const parsedBackUID = JSON.parse(customUID);
              if (Array.isArray(parsedBackUID) && parsedBackUID.length > 0) {
                editorField.entity_files = {
                  uuid: frontUID,
                  files: parsedBackUID.map((id: any) => ({
                    id,
                    tag: ""
                  })),
                };
              }
            } catch (error) {
              console.error('Failed to parse backUID:', error);
            }
          }
          customFieldValues.push(editorField);
        } else if(INPUT_FILED.STRING || INPUT_FILED.NUMBER || DATE_OPTIONS.DATE){
          customFieldValues.push({
            custom_field_id: Number(key),
            value: values.customFields[key]
          })
        }  
      }
    })

    if (mode === 'edit' && taskId) {
      customFieldValues.forEach((field: any) => {
      field.id = task?.data?.custom_field_values?.find((cf: any) => cf.custom_field_id === field.custom_field_id)?.id
      })
      customFieldEntityValues.forEach((field: any) => {
      field.id = task?.data?.custom_field_entity_values?.find((cf: any) => cf.custom_field_id === field.custom_field_id)?.id
      })
    }
    try {
      const backUID = localStorage.getItem('uuids');
      const frontUID = localStorage.getItem('frontId');
      
      let data: any = {
        title: title,
        content: values.content,
        due_date: dueDate ? new Date(dueDate.getTime() + (mode === 'create' ? (24 * 60 * 60 * 1000) : 0)) : null,
        task_type_id: selectedTaskType?.value,
        assignee_id: selectedAssignedTo?.value,
        creator_id: userInfo?.data?.id,
        status_id: selectedStatus?.value,
        custom_field_values: customFieldValues,
        custom_field_entity_values: customFieldEntityValues
      }

      if (backUID) {
        try {
          const parsedBackUID = JSON.parse(backUID);
          if (Array.isArray(parsedBackUID) && parsedBackUID.length > 0) {
            data = {
              ...data,
              entity_files: {
                uuid: frontUID,
                files: parsedBackUID.map((id: any) => ({
                  id,
                  tag: ""
                })),
              },
            };
          }
        } catch (error) {
          console.error('Failed to parse backUID:', error);
        }
      }  
      data.status = 'published'
      if (mode === 'create') {
        await createTask({ data: data }).unwrap()
        navigate(paths.tasks)
      } else if (mode === 'edit' && taskId) {
        await updatetask({ id: taskId, data: data }).unwrap()
        navigate(paths.tasks)
      }
    } catch (error) {
      console.error('Failed to create/update investigation:', error)
    }
  }

  function goToStep1() {
    setStep1(true)
    setStep2(false)
  }
  
  function goToStep2() {
    setStep2(true)
    setStep1(false)
  }
  function goToStepBack2() {
    setStep2(true)
    setStep3(false)
  }

  function goToStep3() {
    setStep3(true)
    setStep2(false)
  }


  return (
    <FormPaper>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        enableReinitialize={true}

      >
        {({ values, setFieldValue }) => (
          <Form>
            <FormHeader>
              <div>
                <b>
                  {mode === 'edit'
                    ? formatMessage({
                      id: 'task.edit',
                      defaultMessage: 'Edit: '
                    }) + title
                    : formatMessage({
                      id: 'task.create',
                      defaultMessage: 'Create Task'
                    })}
                </b>
              </div>
              <Actions>
                <Button
                  appearance="secondary"
                  type="reset"
                  onClick={() => {
                    navigate(paths.tasks)
                  }}
                >
                  {formatMessage({
                    id: 'investigationForm.cancel',
                    defaultMessage: 'Cancel'
                  })}
                </Button>
                <Button style={{cursor: !selectedTaskType ? 'default' : 'pointer'}} disabled={!selectedTaskType} appearance="primary" type="submit">
                  {mode === 'create'
                    ? formatMessage({
                      id: 'investigationForm.publish',
                      defaultMessage: 'Publish'
                    })
                    : formatMessage({
                      id: 'investigationForm.save',
                      defaultMessage: 'Save'
                    })}
                </Button>
              </Actions>
            </FormHeader>
            {mode === 'create' && (
                <Progressbar step1={step1} step2={step2} step3={step3} mainColor={mainColor} />
            )}

            {step1 && (
            <>
            <TabContent>
              <FormContent>
                <ColumnTopLevel>
                      <>
                    <TaskTypeContainer>
                      <CustomSelect
                        label={formatMessage({ id: 'task.assignTo', defaultMessage: 'Assign to' })}
                        value={selectedAssignedTo}
                        options={optionsAssignedTo}
                        onChange={(selectedOption) => setSelectedAssignedTo(selectedOption)}
                        placeholder={formatMessage({ id: 'task.assignToPlaceholder', defaultMessage: 'Select a user...' })}
                      />
                    </TaskTypeContainer>

                    <TaskTypeContainer>
                      <CustomSelect
                        disabled={mode === 'edit' ? true : false}  
                        label={formatMessage({ id: 'task.taskType', defaultMessage: 'Task type' })}
                        value={selectedTaskType}
                        options={optionsTaskType}
                        onChange={(selectedOption) => setSelectedTaskType(selectedOption)}
                        placeholder={formatMessage({ id: 'taskType.selectType', defaultMessage: 'Select a task type...' })}
                      />
                    </TaskTypeContainer>     
                    <LabelWrapper>
                      <Label>{formatMessage({ id: 'task.dueDate', defaultMessage: 'Due Date' })}</Label>
                      <DatePicker
                        selected={dueDate || undefined}
                        onChange={(date) => setDueDate(date)}
                        dateFormat="yyyy-MM-dd"
                        autoComplete="off"
                        placeholderText="yyyy-MM-dd"
                        className={styles.datePicker}
                        calendarClassName={styles.calendar}
                        onFocus={(e) => e.target.style.borderColor = userCompany?.button_color}
                        onBlur={(e) => e.target.style.borderColor = ''}    
                      />
                    </LabelWrapper>                    
                        <Button style={{ cursor: !selectedTaskType ? 'default' : 'pointer', width: '15%' }} disabled={!selectedTaskType} appearance='secondary' onClick={() => {
                          goToStep2()
                        }}>
                          {formatMessage({ id: 'task.nextStep', defaultMessage: 'Next Step' })}
                        <GrNext /> </Button>
                  </>
                </ColumnTopLevel>
              </FormContent>
            </TabContent>
              </>
            )}
            {step2 && (
              <>
                <FormContent>
                <ColumnTopLevel>        
                {Array.isArray(taskTypeOne) && taskTypeOne.map((taskType: any) => {
                  const optionsDropdown = mapOptionsDropdown(dropdownOptions[taskType.id]) || []
                  return (
                    <TabContent key={taskType.id}>
                      {(taskType.field_type === INPUT_FILED.STRING || taskType.field_type === INPUT_FILED.NUMBER) ? (
                        <Row style={{gap: 0}}>
                        <FormikInput
                          label={taskType.translations.find((translation: any) => translation.language_code === intl.locale)?.translated_name}
                          name={`customFields.${taskType.id}`}
                          placeholder={`${formatMessage({ id: 'task.enter', defaultMessage: 'Enter' })} ${taskType.translations.find((translation: any) => translation.language_code === intl.locale)?.translated_name.toLowerCase()}...`}
                          autoComplete="off"
                          required={taskType.is_required} 
                          />
                        </Row>
                      ) : (
                        taskType.field_type === DROPDOW_OPTIONS.USER ||
                        taskType.field_type === DROPDOW_OPTIONS.DEPARTMENT ||
                        taskType.field_type === DROPDOW_OPTIONS.OFFICE ||
                        taskType.field_type === DROPDOW_OPTIONS.JOB_TITLE ||
                        taskType.field_type === DROPDOW_OPTIONS.PROJECT_GROUP ? (
                              <Row>
                          {taskType.is_multiselect ? (
                          <CustomSelectMulti
                            label={
                              <div style={{display: 'flex', flexDirection: 'row', gap: 3}}>
                                {taskType.translations.find((translation: any) => translation.language_code === intl.locale)?.translated_name}
                                {taskType.is_required && <p style={{color: mainColor}}>*</p>}
                              </div>
                            }
                            value={values.customFields[taskType.id]}
                            options={
                              taskType.field_type === DROPDOW_OPTIONS.USER
                                ? optionsUser
                                : taskType.field_type === DROPDOW_OPTIONS.DEPARTMENT
                                  ? optionsDepartment
                                  : taskType.field_type === DROPDOW_OPTIONS.OFFICE
                                    ? optionsOffice
                                    : taskType.field_type === DROPDOW_OPTIONS.JOB_TITLE
                                      ? optionsJobTitle
                                      : taskType.field_type === DROPDOW_OPTIONS.PROJECT_GROUP
                                      && optionsProjectGroup
                            }
                            onChange={(selectedOption) => setFieldValue(`customFields.${taskType.id}`, selectedOption)}
                            placeholder={`${formatMessage({ id: 'task.select', defaultMessage: 'Select' })} ${taskType.translations.find((translation: any) => translation.language_code === intl.locale)?.translated_name.toLowerCase()}...`}
                                  />) : (
                                    <CustomSelect
                                    label={
                                      <div style={{display: 'flex', flexDirection: 'row', gap: 3}}>
                                        {taskType.translations.find((translation: any) => translation.language_code === intl.locale)?.translated_name}
                                        {taskType.is_required && <p style={{color: mainColor}}>*</p>}
                                      </div>
                                    }
                                    value={values.customFields[taskType.id]}
                                    options={
                                      taskType.field_type === DROPDOW_OPTIONS.USER
                                        ? optionsUser
                                        : taskType.field_type === DROPDOW_OPTIONS.DEPARTMENT
                                          ? optionsDepartment
                                          : taskType.field_type === DROPDOW_OPTIONS.OFFICE
                                            ? optionsOffice
                                            : taskType.field_type === DROPDOW_OPTIONS.JOB_TITLE
                                              ? optionsJobTitle
                                              : taskType.field_type === DROPDOW_OPTIONS.PROJECT_GROUP
                                              && optionsProjectGroup
                                    }
                                    onChange={(selectedOption) => setFieldValue(`customFields.${taskType.id}`, selectedOption)}
                                    placeholder={`${formatMessage({ id: 'task.select', defaultMessage: 'Select' })} ${taskType.translations.find((translation: any) => translation.language_code === intl.locale)?.translated_name.toLowerCase()}...`}
                                  />
                                  )}
                                    
                          </Row>
                          ) : taskType.field_type === DATE_OPTIONS.DATE ? (
                          <Row>
                          <LabelWrapper>
                                  <Label>
                                    <div style={{ display: 'flex', flexDirection: 'row', gap: 4 }}>
                                      {taskType.translations.find((translation: any) => translation.language_code === intl.locale)?.translated_name}{taskType.is_required && <p style={{ color: mainColor }}>*</p>}
                                    </div>
                                  </Label>
                            <DatePicker
                              selected={values.customFields[taskType.id] || undefined}      
                              onChange={(date) => {
                                setFieldValue(`customFields.${taskType.id}`, date)
                              }}
                              dateFormat="yyyy-MM-dd"
                              autoComplete="off"
                              placeholderText="yyyy-MM-dd"
                              className={styles.datePicker}
                              calendarClassName={styles.calendar}
                              onFocus={(e) => e.target.style.borderColor = userCompany?.button_color}
                              onBlur={(e) => e.target.style.borderColor = ''}
                            />
                              </LabelWrapper>
                            </Row>
                            ) : taskType.field_type === TASK_FORM_OPTIONS.DROPDOWN ? (
                                <Row>
                                  {taskType.is_multiselect ? (
                                    <CustomSelectMulti
                                      label={
                                        <div style={{ display: 'flex', flexDirection: 'row', gap: 3 }}>
                                          {taskType.translations.find((translation: any) => translation.language_code === intl.locale)?.translated_name}
                                          {taskType.is_required && <p style={{ color: mainColor }}>*</p>}
                                        </div>
                                      }
                                      value={values.customFields[taskType.id]}
                                      options={optionsDropdown}
                                      onChange={(selectedOption) => setFieldValue(`customFields.${taskType.id}`, selectedOption)}
                                      placeholder={`${formatMessage({ id: 'task.select', defaultMessage: 'Select' })} ${taskType.translations.find((translation: any) => translation.language_code === intl.locale)?.translated_name.toLowerCase()}...`}
                                    />
                                  ) : (
                            <CustomSelect
                              label={
                                <div style={{ display: 'flex', flexDirection: 'row', gap: 3 }}>
                                  {taskType.translations.find((translation: any) => translation.language_code === intl.locale)?.translated_name}
                                  {taskType.is_required && <p style={{ color: mainColor }}>*</p>}
                                </div>
                              }
                            value={values.customFields[taskType.id]}
                            options={optionsDropdown}
                            onChange={(selectedOption) => setFieldValue(`customFields.${taskType.id}`, selectedOption)}
                            placeholder={`${formatMessage({ id: 'task.select', defaultMessage: 'Select' })} ${taskType.translations.find((translation: any) => translation.language_code === intl.locale)?.translated_name.toLowerCase()}...`}
                          />)}
                          </Row>      
                        ) : (
                          <Row>
                            <LabelField style={{marginBottom: 0}}>{taskType.translations.find((translation: any) => translation.language_code === intl.locale)?.translated_name}</LabelField>
                            <WangEditorTaskCustomField name={`customFields.${taskType.id}`} />     
                          </Row>
                          )
                      )}
                    </TabContent>
                  )})}                     
                   </ColumnTopLevel>
                </FormContent>      
                <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginTop: '20px' }}>
                  <Button appearance='secondary' onClick={() => {          
                    goToStep1()
                }}>
                  <GrPrevious />
                  {formatMessage({ id: 'task.previousStep', defaultMessage: 'Previous Step' })}
                </Button>

                  <Button style={{ cursor: !selectedTaskType ? 'default' : 'pointer' }} appearance='primary' onClick={() => {
                    goToStep3()
                }} >
                {formatMessage({ id: 'task.nextStep', defaultMessage: 'Next Step' })}
                <GrNext />
                  </Button>
                </div>
              </>
            )}

            {step3 && (
              <>
                 <FormContent>
                  <ColumnTopLevel>  
                  <Row style={{ gap: 0}}>
                    <CustomSelect
                      label={'Status'}
                       value={selectedStatus}
                       options={optionsStatus}
                       onChange={(selectedOption) => setSelectedStatus(selectedOption)}
                       placeholder={`${formatMessage({ id: 'task.select', defaultMessage: 'Select' })} status...`}
                      />
                  </Row>  
               <Row style={{ gap: 0 }}>
                        <LabelField>{formatMessage({ id: 'task.title', defaultMessage: 'Title' })}</LabelField>
                      <TextArea
                      color={buttonColor}
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                      placeholder={formatMessage({ id: 'task.titlePlaceholder', defaultMessage: 'Enter a title for the task' })}
                    />
                  </Row>
                  <Row style={{ gap: 0 }}>
                    <WangEditorTask name="content" />
                    </Row> 
                  </ColumnTopLevel>
                </FormContent>
                <Button appearance='secondary' style={{ marginTop: 20 }} onClick={() => {
                  goToStepBack2()
                }}>
                  <GrPrevious />
                  {formatMessage({ id: 'task.previousStep', defaultMessage: 'Previous Step' })}
                </Button>
              </>
            )}
          </Form>
        )}
      </Formik>
    </FormPaper>
  )
}

export default TaskForm



