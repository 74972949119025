export enum TaskStatus {
    PLANNED = 'planned',
    ONGOING = 'ongoing',
    ONGOING_EVALUATION = 'ongoing_evaluation',
    OVERDUE = 'overdue',
    NOT_COMPLETED = 'not_completed',
    COMPLETED = 'completed',
    COMPLETED_WITH_EVALUATION = 'completed_with_evaluation',
    COMPLETED_WITHOUT_EVALUATION = 'completed_without_evaluation',
    EVALUATION_POSSIBLE = 'evaluation_possible',
    CANCELLED = 'cancelled',
    ARCHIVED = 'archived'
}