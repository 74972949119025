import React from 'react';
import { Checkbox } from '../../../../ui-kit/Form/components/Checkbox/Checkbox';
interface CheckBoxProps {
    isChecked: boolean;
    onChange: (checked: boolean) => void;
    label: string;
}

const CheckBox: React.FC<CheckBoxProps> = ({ isChecked, onChange, label }) => {
    return (
        <div style={{ marginTop: '1rem' }}>
            <Checkbox
                checked={isChecked}
                onChange={(e) => onChange(e.target.checked)}
                label={<p style={{fontSize: 14}}>{label}</p>}
            />
        </div>
    );
};

export default CheckBox;