export enum CompanyTabsPermissions {
    companyViewOwn = 'company: view own',
    companyCreate = 'company: create',
    departmentView = 'department: view',
    officeView = 'office: view',
    jobTitleView = 'job title: view',
    projectGroupView = 'project group: view',
    userImport = 'user: import',
    taskTypeView = 'task type: view'
};
