import React from 'react';
import styled from 'styled-components';
import { FaCheck } from 'react-icons/fa';

interface StepIndicatorProps {
  step1: boolean;
  step2: boolean;
  step3: boolean;
  mainColor: string;
}

const StepContainer = styled.div`
  position: relative;
  margin-top: 10px;
  margin-bottom: 20px;
`;

const StepWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: relative;
  z-index: 1;
`;

const StepCircle = styled.div<{ mainColor: string }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 35px;
  width: 35px;
  background-color: ${(props) => props.mainColor};
  border-radius: 50%;
`;

const ProgressBarBackground = styled.div`
  width: 100%;
  background-color: #e0e0df;
  border-radius: 5px;
  height: 10px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
`;

const ProgressBar = styled.div<{ width: string; mainColor: string }>`
  width: ${(props) => props.width};
  background-color: ${(props) => props.mainColor};
  height: 10px;
  border-radius: 5px;
`;

const StepIndicator: React.FC<StepIndicatorProps> = ({ step1, step2, step3, mainColor }) => {
  return (
    <StepContainer>
      <StepWrapper>
        <StepCircle mainColor={mainColor}>
          {step1 ? <p style={{ color: 'white', fontWeight: 'bold' }}>1</p> : <FaCheck style={{ color: 'white' }} />}
        </StepCircle>
        <StepCircle mainColor={mainColor}>
          {step1 || step2 ? <p style={{ color: 'white', fontWeight: 'bold' }}>2</p> : <FaCheck style={{ color: 'white' }} />}
        </StepCircle>
        <StepCircle mainColor={mainColor}>
          {step1 || step2 || step3 ? <p style={{ color: 'white', fontWeight: 'bold' }}>3</p> : <FaCheck style={{ color: 'white' }} />}
        </StepCircle>
      </StepWrapper>
      <ProgressBarBackground>
        <ProgressBar width={step1 ? '0%' : step2 ? '50%' : '100%'} mainColor={mainColor} />
      </ProgressBarBackground>
    </StepContainer>
  );
};

export default StepIndicator;