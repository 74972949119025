import { paths } from '../../config/paths'
import { LuArrowLeft} from 'react-icons/lu'
import React, { useEffect, useState, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useGetOneTaskQuery, useGetOneTaskRegularQuery } from '../../slices/taskApiSlice'
import { RiArrowDropDownLine, RiArrowDropUpLine } from "react-icons/ri";
import { formatMessage } from '../../locale'
import { useIntlContext } from '../../locale/IntlProviderWrapper'
import { useSelector } from 'react-redux'
import {
    Container, Header, Title, BackLink,
    Content, TaskContent, TaskType, InfoButton,
    InfoTitle, InfoSection, UserInfo, UserAvatar,
    UserInitials, UserName, Status, Dates, Footer,
    InfoContainer, ContainerTask, HeaderTask, Row,
    Label, FieldType, FieldValue, EditableField, IconButton, CustomSelectMultiContainer, HideOnMobile
} from './TaskPage.style'
import { TaskStatus } from '../../enum/Task/TaskStatus'
import { HtmlView } from '../../ui-kit/HtmlView/HtmlView'
import { CustomFieldPermisson } from '../../enum/TaskPermisson/CustomFieldPermisson'
import { LuPlusCircle } from 'react-icons/lu'
import { Form, Formik } from 'formik'
import CustomSelectMulti from '../../ui-kit/Dropdown/CustomDropdwonOverFlowParent/TaskDropdownMulti'
import CustomSelect from '../../ui-kit/Dropdown/CustomDropdwonOverFlowParent/TaskDropdown'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import styles from './DatePicker.module.css'
import {
    DROPDOW_OPTIONS,
    INPUT_FILED,
    DATE_OPTIONS,
    TASK_FORM_OPTIONS,
    EDITOR
} from '../../enum/Task/TaskFormPermisson'
import {
    useGetOptionValueCustomFieldsQuery
} from '../../slices/taskTypeApiSlice'
import ButtonSecondary from '../../ui-kit/Button/ui/components/ButtonSecondary'
import WangEditor from '../../ui-kit/Form/components/Editor/WysiwygEditor'


export const TaskPage = () => {
    const { intl } = useIntlContext();
    const { id } = useParams();
    const { userCompany, userInfo } = useSelector((state: any) => state.auth);
    
    const currentUser = userInfo?.data?.role?.name === 'power_user' || userInfo?.data?.role?.name === 'super_admin'

    const { data: task, refetch: oneTaskrefetch,  error, isLoading } = useGetOneTaskQuery(
        { id, update: true },
        { skip: !currentUser }
    );

    const { data: taskRegular, refetch: oneTaskRegularrefetch  } = useGetOneTaskRegularQuery(
        { id, update: true },
        { skip: currentUser }
    );

    useEffect(() => {
        if (currentUser) {
            oneTaskrefetch();
        } else {
            oneTaskRegularrefetch();
        }
    }, []);

    const taskData = task?.data || taskRegular?.data;
    const color = userCompany?.button_color || 'rgba(164, 54, 109, 1)'

    const [showInfo, setShowInfo] = useState(true);
    const [backgroundColor, setBackgroundColor] = useState('');
    const [fontColor, setFontColor] = useState('#6f7669;');
    const [showEditor, setShowEditor] = useState(false);
    
    useEffect(() => {
        if (taskData?.status?.name) {
            switch (taskData?.status?.name) {
                case TaskStatus.COMPLETED:
                case TaskStatus.COMPLETED_WITH_EVALUATION:
                case TaskStatus.COMPLETED_WITHOUT_EVALUATION:
                    setBackgroundColor('rgba(26, 229, 190, 0.15)');
                    setFontColor('rgba(0, 147, 119, 1)');
                    break;
                case TaskStatus.CANCELLED:
                case TaskStatus.NOT_COMPLETED:
                case TaskStatus.OVERDUE:
                    setFontColor('rgba(127, 95, 184, 1)');
                    setBackgroundColor('rgba(219, 208, 245, 0.3)');
                    break;
                case TaskStatus.ONGOING:
                case TaskStatus.ONGOING_EVALUATION:
                    case TaskStatus.ARCHIVED: 
                    setFontColor('rgba(146, 105, 0, 1)');
                    setBackgroundColor('rgba(255, 231, 170, 0.4)');
                    break;
                case TaskStatus.EVALUATION_POSSIBLE:
                case TaskStatus.PLANNED:
                    setBackgroundColor('#ced0cb');
                    setFontColor('#6f7669');
                    break;
                default:
                    break;
            }
        }
    }, [taskData?.status]);


    const initialValues = {
        content: '',
        customFields: taskData?.custom_fields?.filter((field: any) => field.is_output).reduce((acc: any, field: any) => {
            const entityValue = taskData?.custom_field_entity_values?.find((cf: any) => cf.custom_field_id === field.id)?.value;
            const fieldValue = taskData?.custom_field_values?.find((cf: any) => cf.custom_field_id === field.id)?.value;
            switch (field.field_type) {
                case DROPDOW_OPTIONS.USER:
                case DROPDOW_OPTIONS.DEPARTMENT:
                case DROPDOW_OPTIONS.OFFICE:
                case DROPDOW_OPTIONS.JOB_TITLE:
                case DROPDOW_OPTIONS.PROJECT_GROUP:
                    acc[field.id] = entityValue ? Array.isArray(entityValue) ? entityValue.map((val: any) => ({ value: val.id, label: val.name })) : { value: entityValue.id, label: entityValue.name } : null;
                    break;
                case TASK_FORM_OPTIONS.DROPDOWN:
                    acc[field.id] = fieldValue ? fieldValue.map((val: any) => ({
                        value: val.id,
                        label: taskData?.custom_fields?.find((cf: any) => cf.id === field.id)?.options?.find((option: any) => option.id === val.id)?.translations.find((translation: any) => translation.language_code === intl.locale)?.translated_value
                    })) : [];
                    break;
                case INPUT_FILED.STRING:
                case INPUT_FILED.NUMBER:
                    acc[field.id] = fieldValue || "";
                    break;
                case DATE_OPTIONS.DATE:
                    if (fieldValue && typeof fieldValue === 'object' && fieldValue.date) {
                        acc[field.id] = fieldValue.date || null;
                    }
                    break;
                case EDITOR.EDITOR:
                    acc[field.id] = fieldValue || '';
                    break;
                default:
                    break;
            }
            return acc;
        }, {}) || {},
    };

    const formatDisplayValue = (value: any, fieldType: string) => {
        if (Array.isArray(value)) {
            return value.map((val: any) => val.label || val.name).join(', ');
        }
        if (typeof value === 'object' && value !== null) {
            return value.label || value.name || 'N/A';
        }
        if (fieldType === DATE_OPTIONS.DATE && value) {
            return new Date(value).toLocaleDateString('en-CA');
        }
        return value || 'N/A';
    };

    const mapOptions = (optionsData: any) => optionsData?.data?.map((option: any) => ({
        value: option.id,
        label: option.name || option.fullname,
    }));

    const { data: userOptions } = useGetOptionValueCustomFieldsQuery(
        { field_type: DROPDOW_OPTIONS.USER },
        {
            skip: !taskData?.custom_fields?.some((field: any) =>
                field.field_type === DROPDOW_OPTIONS.USER)
        }
    );

    const { data: departmentOptions } = useGetOptionValueCustomFieldsQuery(
        { field_type: DROPDOW_OPTIONS.DEPARTMENT },
        {
            skip: !taskData?.custom_fields?.some((field: any) =>
                field.field_type === DROPDOW_OPTIONS.DEPARTMENT)
        }
    );

    const { data: officeOptions } = useGetOptionValueCustomFieldsQuery(
        { field_type: DROPDOW_OPTIONS.OFFICE },
        {
            skip: !taskData?.custom_fields?.some((field: any) =>
                field.field_type === DROPDOW_OPTIONS.OFFICE)
        }
    );

    const { data: jobTitleOptions } = useGetOptionValueCustomFieldsQuery(
        { field_type: DROPDOW_OPTIONS.JOB_TITLE },
        {
            skip: !taskData?.custom_fields?.some((field: any) =>
                field.field_type === DROPDOW_OPTIONS.JOB_TITLE)
        }
    );

    const { data: projectGroupOptions } = useGetOptionValueCustomFieldsQuery(
        { field_type: DROPDOW_OPTIONS.PROJECT_GROUP },
        {
            skip: !taskData?.custom_fields?.some((field: any) =>
                field.field_type === DROPDOW_OPTIONS.PROJECT_GROUP)
        }
    );

    const optionsUser = mapOptions(userOptions);
    const optionsDepartment = mapOptions(departmentOptions);
    const optionsOffice = mapOptions(officeOptions);
    const optionsJobTitle = mapOptions(jobTitleOptions);
    const optionsProjectGroup = mapOptions(projectGroupOptions);

    const dropdownOptions = useMemo(() => {
        return taskData?.custom_fields?.reduce((acc: any, field: any) => {
            if (field.field_type === TASK_FORM_OPTIONS.DROPDOWN) {
                acc[field.id] = field.options?.map((option: any) => {
                    const translatedValue = option.translations.find((translation: any) => translation.language_code === intl.locale)?.translated_value || '';
                    console.log(option)
                    return { ...option, value: option.id, label: translatedValue };
                }) || [];
            }
            console.log(acc);
            return acc;
        }, {}) || {};
    }, [taskData, intl.locale]);

    if (isLoading) return <div><p>{formatMessage({ id: 'loading.loading', defaultMessage: 'Loading...' })}</p></div>;
    if (error) return <div><p>{formatMessage({ id: 'loading.errorLoadingTask', defaultMessage: 'Error while loading Task' })}</p></div>;



    const handleSubmit = async (values: any) => { 
        console.log(values)
    }
    return (
        <Container>
            <Header>
                <Title>{taskData.title}</Title>
                <BackLink to={paths.tasks} color={color}>
                    <LuArrowLeft size={12} />{' '}
                    {formatMessage({
                        id: 'task.backTo',
                        defaultMessage: 'BACK TO TASK',
                    })}
                </BackLink>
            </Header>
            <Content>
                <div style={{width: '70%'}}>
                    <HtmlView content={taskData?.content || ''}/>
                    
                    <ContainerTask>
                        {taskData?.custom_fields?.filter((field: any) => field.is_input).map((field: any, index: number, array: any[]) => {
                            const translation = field.translations.find((translation: any) => translation.language_code === intl.locale);
                            const fieldName = translation ? translation.translated_name : 'N/A';
                            let customFieldValue = taskData?.custom_field_values?.find((cfv: any) => cfv.custom_field_id === field.id);

                            let displayValue = '';
                            if (customFieldValue && typeof customFieldValue.value === 'object' && customFieldValue.value !== null && field.field_type === CustomFieldPermisson.DROPDOWN) {
                                displayValue = customFieldValue.value.map((val: any) => {
                                    return field.options.find((option: any) => option.id === val.id)?.translations.find((translation: any) => translation.language_code === intl.locale)?.translated_value || 'N/A';
                                }).join(', ');
                            } else if (customFieldValue && (field.field_type === CustomFieldPermisson.STRING || field.field_type === CustomFieldPermisson.NUMBER)) {
                                displayValue = customFieldValue?.value || 'N/A';
                            } else if (customFieldValue && field.field_type === CustomFieldPermisson.DATE) {
                                displayValue = new Date(customFieldValue?.value.date).toLocaleDateString('en-CA') || 'N/A';
                            } else {
                                const value = taskData?.custom_field_entity_values.find((cfv: any) => cfv.custom_field_id === field.id)?.value;
                                displayValue = Array.isArray(value) ? value.map((v: any) => v.name).join(', ') : value?.name || 'N/A';
                            }                       
                            return (
                                <Row key={field.id} style={{ borderRadius: index === array.length - 1 ? 25 : 0, borderTopLeftRadius: index === 0 ? 25 : 0, borderTopRightRadius: index === 0 ? 25 : 0, paddingTop: index === 0 ? '20px' : '15px', paddingBottom: index === array.length - 1 ? '25px' : '20px' }}>
                                    <FieldValue>{fieldName || 'N/A'}</FieldValue>
                                    <FieldValue>{displayValue || 'N/A'}</FieldValue> 
                                </Row>
                            );
                        })}
                    </ContainerTask>
                    <Formik
                        initialValues={initialValues}
                        onSubmit={handleSubmit}
                    >
                        {({ values,  handleChange, setFieldValue }) => (
                            <Form>
                                <ContainerTask>
                                    {taskData?.custom_fields?.filter((field: any) => field.is_output).map((field: any, index: number, array: any[]) => {
                                        const fieldName = field.translations.find((translation: any) => translation.language_code === intl.locale)?.translated_name || 'N/A';
                                        const optionsDropdown = dropdownOptions[field.id] || [];
                                        if (field.field_type === EDITOR.EDITOR) return null;
                                        

                                        return (
                                            <Row key={field.id} style={{ borderRadius: index === array.length - 1 ? 25 : 0, borderTopLeftRadius: index === 0 ? 25 : 0, borderTopRightRadius: index === 0 ? 25 : 0, paddingTop: index === 0 ? '20px' : '15px', paddingBottom: index === array.length - 1 ? '25px' : '20px' }}>
                                                    <>
                                                        {field.field_type === INPUT_FILED.STRING || field.field_type === INPUT_FILED.NUMBER ? (
                                                         <>                    
                                                            <FieldValue>{fieldName}</FieldValue>
                                                            <EditableField
                                                                type="text"
                                                                name={`customFields.${field.id}`}
                                                                value={values.customFields[field.id]}
                                                                color={color}
                                                                onChange={handleChange}
                                                                placeholder={`${formatMessage({ id: 'task.enter', defaultMessage: 'Enter' })} ${fieldName.toLowerCase()}...`}
                                                            />
                                                        </>
                                                        ) : field.field_type === TASK_FORM_OPTIONS.DROPDOWN ? (
                                                                field.is_multiselect ? (
                                                                <>                    
                                                                    <FieldValue>{fieldName}</FieldValue>
                                                                <CustomSelectMultiContainer>
                                                                        <CustomSelectMulti
                                                                        label={fieldName}     
                                                                        value={values.customFields[field.id]}
                                                                        options={optionsDropdown}
                                                                        onChange={(selectedOption) => setFieldValue(`customFields.${field.id}`, selectedOption)}
                                                                        placeholder={`${formatMessage({ id: 'task.select', defaultMessage: 'Select' })} ${fieldName.toLowerCase()}...`}
                                                                    />
                                                                </CustomSelectMultiContainer>
                                                                </>
                                                                ) : (
                                                                <>                    
                                                                    <FieldValue>{fieldName}</FieldValue>
                                                                <CustomSelectMultiContainer>
                                                                    <CustomSelect
                                                                    label={fieldName}     
                                                                    value={values.customFields[field.id]}
                                                                    options={optionsDropdown}
                                                                    onChange={(selectedOption) => setFieldValue(`customFields.${field.id}`, selectedOption)}
                                                                    placeholder={`${formatMessage({ id: 'task.select', defaultMessage: 'Select' })} ${fieldName.toLowerCase()}...`}
                                                                            />
                                                                </CustomSelectMultiContainer>
                                                                </>
                                                            )
                                                            ) : field.field_type === DATE_OPTIONS.DATE ? (
                                                            <>                    
                                                                <FieldValue>{fieldName}</FieldValue>
                                                            <DatePicker
                                                                selected={values.customFields[field.id] || undefined}
                                                                onChange={(date) => setFieldValue(`customFields.${field.id}`, date)}
                                                                dateFormat="yyyy-MM-dd"
                                                                autoComplete="off"
                                                                placeholderText="yyyy-MM-dd"
                                                                className={styles.datePicker}
                                                                calendarClassName={styles.calendar}
                                                                onFocus={(e) => e.target.style.borderColor = userCompany?.button_color}
                                                                onBlur={(e) => e.target.style.borderColor = ''}
                                                            />
                                                            </>
                                                        ) : (
                                                            (field.field_type === DROPDOW_OPTIONS.USER || 
                                                            field.field_type === DROPDOW_OPTIONS.DEPARTMENT ||
                                                            field.field_type === DROPDOW_OPTIONS.OFFICE ||
                                                            field.field_type === DROPDOW_OPTIONS.JOB_TITLE ||
                                                            field.field_type === DROPDOW_OPTIONS.PROJECT_GROUP) && (
                                                            field.is_multiselect ? (
                                                                <>                    
                                                                    <FieldValue>{fieldName}</FieldValue>
                                                                    <CustomSelectMultiContainer>
                                                                     <CustomSelectMulti
                                                                            label={fieldName}     
                                                                            value={values.customFields[field.id]}
                                                                            options={field.field_type === DROPDOW_OPTIONS.USER ? optionsUser : field.field_type === DROPDOW_OPTIONS.DEPARTMENT ? optionsDepartment : field.field_type === DROPDOW_OPTIONS.OFFICE ? optionsOffice : field.field_type === DROPDOW_OPTIONS.JOB_TITLE ? optionsJobTitle : optionsProjectGroup}
                                                                            onChange={(selectedOption) => setFieldValue(`customFields.${field.id}`, selectedOption)}
                                                                            placeholder={`${formatMessage({ id: 'task.select', defaultMessage: 'Select' })} ${fieldName.toLowerCase()}...`}
                                                                        />
                                                                    </CustomSelectMultiContainer>
                                                                </>
                                                                ) : (
                                                                    <>                    
                                                                    <FieldValue>{fieldName}</FieldValue>
                                                                    <CustomSelectMultiContainer>
                                                                        <CustomSelect
                                                                            label={fieldName}                    
                                                                            value={values.customFields[field.id]}
                                                                            options={field.field_type === DROPDOW_OPTIONS.USER ? optionsUser : field.field_type === DROPDOW_OPTIONS.DEPARTMENT ? optionsDepartment : field.field_type === DROPDOW_OPTIONS.OFFICE ? optionsOffice : field.field_type === DROPDOW_OPTIONS.JOB_TITLE ? optionsJobTitle : optionsProjectGroup}
                                                                            onChange={(selectedOption) => setFieldValue(`customFields.${field.id}`, selectedOption)}
                                                                            placeholder={`${formatMessage({ id: 'task.select', defaultMessage: 'Select' })} ${fieldName.toLowerCase()}...`}
                                                                        />
                                                                    </CustomSelectMultiContainer>
                                                                </>
                                                                )
                                                            )
                                                        )}
                                                    </>                                              
                                            </Row>
                                        );
                                    })}
                                </ContainerTask>

                                {taskData?.custom_fields?.filter((field: any) => field.is_output).map((field: any) => {
                                    const fieldName = field.translations.find((translation: any) => translation.language_code === intl.locale)?.translated_name || 'N/A';

                                    return (
                                        <div key={field.id} style={{marginTop: 20}}>
                                            <Label style={{textAlign: 'left', marginBottom: '0.5rem'}}>{fieldName}</Label>
                                            <WangEditor name={`customFields.${field.id}`} />
                                        </div>
                                    );
                                })}
                            </Form>
                        )}
                        
                    </Formik>

                    

                    <Footer>
                        {taskData?.completed_date && <p style={{ color: 'green' }}>{formatMessage({ id: 'task.completedDate', defaultMessage: 'Completed date:' })} {taskData?.completed_date}</p>}
                        {taskData?.due_date && <p style={{ color: 'red' }}>{formatMessage({ id: 'task.dueDate', defaultMessage: 'Due Date' })}: {taskData?.due_date}</p>}
                    </Footer>
                </div>
              
                <div>
                    <TaskType>{formatMessage({ id: 'task.taskType', defaultMessage: 'Task type' })}: {taskData?.task_type.name}</TaskType>
               
                    <InfoContainer>
                        <InfoButton onClick={()=>{setShowInfo(!showInfo)}}>
                            <InfoTitle>Information</InfoTitle>
                            {showInfo ? <RiArrowDropUpLine size={30} color='grey' /> : <RiArrowDropDownLine size={30} color='grey' />}
                        </InfoButton>
                        {showInfo && (
                        <>
                            <InfoSection>
                                <p style={{color: 'grey', fontWeight: '500'}}>{formatMessage({ id: 'task.assigne', defaultMessage: 'Assigne' })}</p>
                                <UserInfo>
                                    <UserAvatar style={{background: color}}>
                                        <UserInitials>
                                            {taskData?.assignee.fullname.split(' ').map((name: any) => name.charAt(0)).join('')}
                                        </UserInitials>
                                    </UserAvatar>
                                    <UserName>{taskData?.assignee.fullname}</UserName>
                                </UserInfo>
                            </InfoSection>
                            <InfoSection>
                                    <p style={{ color: 'grey', fontWeight: '500' }}>{formatMessage({ id: 'task.creator', defaultMessage: 'Created by: ' })}</p>
                                <UserInfo>
                                    <UserAvatar style={{background: color}}>
                                        <UserInitials>
                                            {taskData?.creator.fullname.split(' ').map((name: any) => name.charAt(0)).join('')}
                                        </UserInitials>
                                    </UserAvatar>
                                    <UserName>{taskData?.creator?.fullname}</UserName>
                                </UserInfo>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 15, gap: 5 }}>
                                    <p style={{color: 'grey', fontWeight: '500'}}>Status:</p>
                                     <Status style={{ backgroundColor: backgroundColor, color: fontColor }}>{taskData?.status?.name}</Status> 
                                </div>
                            </InfoSection>
                        </>
                        )}
                    </InfoContainer>
                    <Dates>
                        {taskData?.updated_at && <p>{formatMessage({ id: 'task.updateAt', defaultMessage: 'Updated at: ' })} {taskData?.updated_at}</p>}
                        {taskData?.created_at && <p>{formatMessage({ id: 'task.createAT', defaultMessage: 'Created at: ' })} {taskData?.created_at}</p>}
                    </Dates>
                </div>
                
            </Content>


        </Container>
    );
};
