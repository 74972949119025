import React from 'react';
import { TableTitle } from './MultiSelect.styles'
import Select from 'react-select'
import { formatMessage } from '../../locale';
import { useIntlContext } from '../../locale/IntlProviderWrapper';
interface MultiSelectProps {
  label: any;
  name: string;
  options: { value: string | number; label: string }[];
  values: any[];
  onChange: (field: string, value: any) => void;
}

export const MultiSelect: React.FC<MultiSelectProps> = ({ label, name, options, values, onChange }) => {


  return (
    <div style={{ position: 'relative', zIndex: 9997 }}>
      <TableTitle>{label}</TableTitle>
      <Select
        isMulti
        name={name}
        options={options}
        value={options?.filter(option => values?.includes(option.value))}
        onChange={(selectedOptions) => {
          const newValues = selectedOptions ? selectedOptions.map(option => option.value) : [];
          onChange(name, newValues);
        }}
        menuPortalTarget={document.body}
        menuPosition="fixed"
        placeholder={formatMessage({ id: 'handbookForm.placeholderMultiSelect', defaultMessage: 'Select...' })}
        styles={{
          menuPortal: base => ({ ...base, zIndex: 9998 })
        }}
      />
    </div>
  );
};

/* @workspace render items hiraclur by child and parent id label is Department */