import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface SelectedTabState {
  selectedTab: number;
}

const initialState: SelectedTabState = {
  selectedTab: 0,
};

const selectedTabSlice = createSlice({
  name: 'selectedTab',
  initialState,
  reducers: {
    setSelectedTab(state, action: PayloadAction<number>) {
      state.selectedTab = action.payload;
    },
  },
});

export const { setSelectedTab } = selectedTabSlice.actions;
export default selectedTabSlice.reducer;