import React, { useState, useEffect, useCallback } from 'react'
import '@wangeditor/editor/dist/css/style.css'
import { Editor, Toolbar } from '@wangeditor/editor-for-react'
import {
  IDomEditor,
  IEditorConfig,
  IToolbarConfig,
  i18nChangeLanguage,
  Boot,
  SlateTransforms,
} from '@wangeditor/editor'
import { useField, useFormikContext } from 'formik'
import { v4 as uuidv4 } from 'uuid'
import {
  useUploadEntityFileMutation,
  useUploadVideoMutation,
} from '../../../../slices/mediaApiSlice'
import { IModalMenu } from '@wangeditor/core/dist/core/src/menus/interface'

interface CustomDomEditor extends IDomEditor {
  customData?: {
    front_uuid: string
  }
}

class FileUploadModalMenu implements IModalMenu {
  key = 'fileUpload'
  title = 'Upload File'
  iconSvg =
    '<svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" width="16" height="16"><path d="M512 64L448 128h-64v256H256l-64 64h192v192h128V448h192l-64-64H576V128h-64zM256 896h512V512H704v320H320V512H256v384zM640 640v-64h-128v-64h-64v64h-128v64h128v64h64v-64h128z"/></svg>'
  tag = 'button'
  showModal = true
  modalWidth = 300
  range: any
  uploadEntityFile: any

  constructor(uploadEntityFile: any) {
    this.uploadEntityFile = uploadEntityFile
  }

  getValue(editor: CustomDomEditor): string | boolean {
    return ''
  }

  isActive(editor: CustomDomEditor): boolean {
    return false
  }

  isDisabled(editor: CustomDomEditor): boolean {
    return false
  }

  exec(editor: CustomDomEditor, value: string | boolean) {}

  getModalPositionNode(editor: CustomDomEditor) {
    return null
  }

  getModalContentElem(editor: CustomDomEditor): HTMLElement {
    const selection = editor.selection
    if (selection) {
      this.range = { anchor: selection.anchor, focus: selection.focus }
    } else {
      this.range = {
        anchor: { path: [0, 0], offset: 0 },
        focus: { path: [0, 0], offset: 0 },
      }
    }

    const $content = document.createElement('div')
    $content.style.padding = '20px'
    const fileInput = document.createElement('input')
    fileInput.type = 'file'
    fileInput.style.marginBottom = '10px'
    const uploadButton = document.createElement('button')
    uploadButton.innerText = 'Upload File'
    uploadButton.type = 'button'
    uploadButton.onclick = async () => {
      const file = fileInput.files?.[0]
      if (file) {
        try {
          const response = await this.uploadEntityFile({
            data: {
              file,
              front_id: editor.customData?.front_uuid,
            },
          })
          const url = response.data.data.file.url
          const name = response.data.data.file.name
          const backUuid = response.data.data.uuid

          const existingUuids = JSON.parse(
            localStorage.getItem('uuids') || '[]'
          )
          const updatedUuids = [...existingUuids, backUuid]

          localStorage.setItem('uuids', JSON.stringify(updatedUuids))

          const linkNode = {
            type: 'link',
            url: url,
            children: [{ text: name }],
          }

          SlateTransforms.insertNodes(editor, linkNode, {
            at: this.range.anchor,
          })
        } catch (error) {
          console.error('File upload failed:', error)
        }
      }
    }
    $content.appendChild(fileInput)
    $content.appendChild(uploadButton)
    return $content
  }
}

interface WangEditorProps {
  name: string
}

const WangEditor: React.FC<WangEditorProps> = ({ name }) => {
  const { setFieldValue } = useFormikContext()
  const [field, meta] = useField(name)
  const [editor, setEditor] = useState<CustomDomEditor | null>(null)
  const [html, setHtml] = useState(field.value || '')
  const [frontUuid, setFrontUuid] = useState(uuidv4())

  const [uploadEntityFile] = useUploadEntityFileMutation()
  const [uploadVideo] = useUploadVideoMutation()

  i18nChangeLanguage('en')

  useEffect(() => {
    localStorage.setItem('frontId', frontUuid)
  }, [])

  const handleChange = (editor: CustomDomEditor) => {
    const content = editor.getHtml()
    setHtml(content)
    setFieldValue(name, content)
  }

  const uploadVideoCustom = useCallback(
    async (file: File, insert: Function) => {
      try {
        const response = await uploadVideo({
          data: {
            file,
            entity_type: 'lesson',
          },
        })
        const url = response.data.data.url
        insert(url)
      } catch (error) {
        console.error('Video upload failed:', error)
      }
    },
    []
  )

  const toolbarConfig: Partial<IToolbarConfig> = {
    insertKeys: {
      index: 100,
      keys: ['fileUpload'],
    },
  }

  const editorConfig: Partial<IEditorConfig> = {
    placeholder: 'Type here...',
    MENU_CONF: {
      uploadImage: {
        fieldName: 'image',
        base64LimitSize: 10 * 1024 * 1024,
        maxNumberOfFiles: 1,
      },
      uploadVideo: {
        fieldName: 'file',
        maxNumberOfFiles: 1,
        customUpload: uploadVideoCustom,
      },
    },
  }

  let registeredMenu = false

  useEffect(() => {
    try {
      const existingMenu = Boot?.toolbarConfig?.toolbarKeys?.find(
        (menu) => menu === 'fileUpload'
      )
      if (
        Boot?.toolbarConfig?.toolbarKeys &&
        !existingMenu &&
        !registeredMenu
      ) {
        registeredMenu = true
        Boot.registerMenu({
          key: 'fileUpload',
          factory: () => new FileUploadModalMenu(uploadEntityFile),
        })
      }
    } catch (e) {
      console.error(e)
    }
  }, [uploadEntityFile])

  useEffect(() => {
    if (editor) {
      editor.customData = { front_uuid: frontUuid }
      if (field.value !== editor.getHtml()) {
        editor.setHtml(field.value || '')
      }
    }
  }, [field.value, editor, frontUuid])

  useEffect(() => {
    return () => {
      if (editor) {
        editor.destroy()
        setEditor(null)
      }
    }
  }, [editor])

  return (
    <div
      style={{
        width: '100%',
        maxWidth: '1320px',
        overflowX: 'auto',
        zIndex: 9998,
      }}
    >
      <div style={{ border: '1px solid #ccc', zIndex: 9999 }}>
        <Toolbar
          editor={editor}
          defaultConfig={toolbarConfig}
          mode="default"
          style={{ borderBottom: '1px solid #ccc', zIndex: 9999 }}
        />
        <Editor
          defaultConfig={editorConfig}
          value={html}
          onCreated={setEditor}
          onChange={handleChange}
          mode="default"
          style={{
            height: '400px',
            overflowY: 'hidden',
            width: '100%',
            zIndex: 9998,
          }}
        />
      </div>
      {meta.touched && meta.error ? (
        <div style={{ color: 'red' }}>{meta.error}</div>
      ) : null}
    </div>
  )
}

export default WangEditor
