import styled from 'styled-components';
import Paper from '../../ui-kit/Paper/Paper'
import { Link } from 'react-router-dom'

const Container = styled(Paper)`
    position: relative;
    padding: 20px;
`;

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid silver;
    margin-bottom: 20px;
    padding: 15px 0;
`;
 const HideOnMobile = styled.span`
  
    @media (max-width: ${({ theme }) => theme.breakPoints.sm}) {
        display: none;
    }
`;

const Title = styled.h2`
    margin: 0;
`;

const BackLink = styled(Link)`
    color: ${(props) => props.color};
`;

const Content = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
`;

const TaskContent = styled.p`
    width: 50%;
`;

const TaskType = styled.p`
    color: #13679e;
    font-weight: bold;
    background-color: #b3dcf6;
    width: fit-content;
    padding: 2px 4px;
    border-radius: 4px;
    font-size: 20px;
    margin-bottom: 20px;
`;

const InfoButton = styled.button`
    width: 100%;
    padding: 10px;
    border: transparent;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-bottom: 0.5px solid lightgrey;
    flex-direction: row;
    justify-content: center;
`;

const InfoContainer = styled.div`
    border: 0.5px solid lightgrey;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const InfoSection = styled.div`
    margin: 15px;
`;

const InfoTitle = styled.p`
    font-weight: 500;
    font-size: 16px;
    color: grey;
`;

const UserInfo = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 5px;
`;

const UserAvatar = styled.div`
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #13679e;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const UserInitials = styled.p`
    color: white;
    font-weight: 400;
    font-size: 14px;
`;

const UserName = styled.p`
    padding: 5px;
    color: grey
`;

const Status = styled.p`
    font-weight: bold;
    width: fit-content;
    padding: 2px 4px;
    border-radius: 4px;
    font-size: 18px;
    text-transform: uppercase;

`;

const Dates = styled.div`
    margin-top: 15px;
    color: grey;
`;

const Footer = styled.div`
    padding: 10px 0;
    margin-top: 20px;
    font-size: 16px;
    font-weight: bold;
    transition: color, opacity;
`;
const ContainerTask = styled.div`
  border: 1px solid lightGrey;
  width: 80%;
  border-radius: 25px;
  text-align: left;
  margin-top: 30px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const HeaderTask = styled.h2`
  background-COLOR: #f5f5f5;
  border-radius: 25px 25px 0 0;
  color: Grey;
  font-weight: bold;
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid lightGrey;
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    fled-wrap: wrap;
    gap: 20px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid lightGrey;
    overflow-y: auto;
    background-color: #f9f9f9;
    border-radius: 25px 25px 25px 25px;
    align-items: center;
`;

const Label = styled.p`
  flex: 1;
  font-weight: bold;
  color: grey;
`;

const FieldType = styled.p`
  flex: 1;
  color: grey;
`;

const FieldValue = styled.p`
  flex: 1;
`;
const EditableField = styled.input<{ color?: string }>`
    width: 300px;
    border-radius: 8px;
    border: 1px solid lightGrey;
    padding: 10px;
    &:hover {
        border-color: ${({ color }) => color};
    }

    &:focus {
        border: 1px solid ${({ color }) => color};
        outline: none;
    }
`;

const IconButton = styled.button`
  background: transparent;
  border-radius: 3px;
  padding: 3px;
  padding-bottom: 1px;
  border: none;
  cursor: pointer;
  font-size: 16px;
  color: #000;
`;

const CustomSelectMultiContainer = styled.div`
    .custom-select-multi {
        z-index: 9999;
    }
`;

export {
    Container,
    Header,
    Title,
    BackLink,
    Content,
    TaskContent,
    TaskType,
    InfoButton,
    InfoContainer,
    InfoSection,
    InfoTitle,
    UserInfo,
    UserAvatar,
    UserInitials,
    UserName,
    Status,
    Dates,
    Footer,
    ContainerTask,
    HeaderTask,
    Row,
    Label,
    FieldType,
    FieldValue,
    EditableField, 
    IconButton,
    CustomSelectMultiContainer,
    HideOnMobile
};
