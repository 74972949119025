import { get } from 'lodash';
import { apiSlice } from './apiSlice';

export const taskApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllTask: builder.query({
      query: ({ search = '', page = 1 }) => ({
        url: `/api/v1/admin/tasks?search=${encodeURIComponent(search)}&page=${page}`,
        method: 'GET',
    }),
  }),
    createTask: builder.mutation({
      query: ({ data }) => ({
        url: `/api/v1/admin/tasks/`,
        method: 'POST',
        body: data,
      }),
    }),
    getOneTask: builder.query({
      query: ({id}) => ({
        url: `/api/v1/admin/tasks/${id}/`,
        method: 'GET',
      }),
    }),
    updateTask: builder.mutation({
      query: ({ data, id }) => ({
        url: `/api/v1/admin/tasks/${id}?_method=put`,
        method: 'POST',
        body: data,
      }),
    }),
      deleteTask: builder.mutation({
        query: ({ id }) => ({
          url: `/api/v1/admin/tasks/${id}/`,
          method: 'DELETE',
      }),
    }),
    getAllTaskRegular: builder.query({
      query: ({ search = '', page = 1 }) => ({
        url: `/api/v1/tasks?search=${encodeURIComponent(search)}&page=${page}`,
        method: 'GET',
      }),
    }),
    getOneTaskRegular: builder.query({
      query: ({id}) => ({
        url: `/api/v1/tasks/${id}/`,
        method: 'GET',
      }),
    }),
  }),
});

export const { useGetAllTaskQuery, useCreateTaskMutation, useGetOneTaskQuery, useUpdateTaskMutation, useDeleteTaskMutation, useGetAllTaskRegularQuery, useGetOneTaskRegularQuery } = taskApiSlice;
